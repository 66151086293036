
.centerTengah {
    position: absolute;   top: 10%;   left: 10%;
    width: 100px; height: 100px; margin: -50px;
    border-radius: 50px;
}


.wrapper11{
	background: white;
    background: -webkit-linear-gradient(top left, white 0%, #66c6ec 100%);
    background: -moz-linear-gradient(top left, white 0%, #66c6ec 100%);
    background: -o-linear-gradient(top left, white 0%, #66c6ec 100%);
    background: linear-gradient(to bottom right, white 0%, #66c6ec 100%);
	
	// position: absolute;
	// top: 50%;
	left: 0;
	// width: 100%;
	height: 100%;
	overflow: hidden;
	
	&.form-success{
		.container{
			h1{
				transform: translateY(85px);
			}
		}
	}
}
 

.bg-bubbles{
	position: absolute;
	top: 0;
	left: 300px;
	// width: 100%;
	height: 100%;
	
	z-index: 1;
	
	li{
		position: absolute;
		list-style: none;
		display: block;
		width: 40px;
		height: 40px;
		background-color: fade(white, 15%);
		bottom: -160px;
		
		-webkit-animation: square 25s infinite;
		animation:         square 25s infinite;
		
		-webkit-transition-timing-function: linear;
		transition-timing-function: linear;
		
		&:nth-child(1){
			left: 10%;
		}
		
		&:nth-child(2){
			left: 20%;
			
			width: 80px;
			height: 80px;
			
			animation-delay: 2s;
			animation-duration: 17s;
		}
		
		&:nth-child(3){
			left: 25%;
			animation-delay: 4s;
		}
		
		&:nth-child(4){
			left: 40%;
			width: 60px;
			height: 60px;
			
			animation-duration: 22s;
			
			background-color: fade(white, 25%);
		}
		
		&:nth-child(5){
			left: 70%;
		}
		
		&:nth-child(6){
			left: 80%;
			width: 120px;
			height: 120px;
			
			animation-delay: 3s;
			background-color: fade(white, 20%);
		}
		
		&:nth-child(7){
			left: 32%;
			width: 160px;
			height: 160px;
			
			animation-delay: 7s;
		}
		
		&:nth-child(8){
			left: 55%;
			width: 20px;
			height: 20px;
			
			animation-delay: 15s;
			animation-duration: 40s;
		}
		
		&:nth-child(9){
			left: 25%;
			width: 10px;
			height: 10px;
			
			animation-delay: 2s;
			animation-duration: 40s;
			background-color: fade(white, 30%);
		}
		
		&:nth-child(10){
			left: 90%;
			width: 160px;
			height: 160px;
			
			animation-delay: 1s;
		}
	}
}

@-webkit-keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-700px) rotate(100deg); }
}
@keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-700px) rotate(100deg); }
}

@primary-color: #258fe6f7;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: #001529;@text-color: black;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: #d8d0d0;@disabled-bg: #d8d0d0;@border-radius-base: 0px;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@btn-disable-color: black;@btn-disable-bg: @disabled-color;@btn-disable-border: @disabled-color;@table-header-bg: #71bdfbd9;@table-header-color: #001529;@table-header-sort-bg: #86c6fbd9;@table-body-sort-bg: #fafafa;@table-row-hover-bg: #3b7ddd24;@input-disabled-color: black;@table-selected-row-hover-bg: #3b7ddd24;@table-expanded-row-bg: rgba(78, 174, 242, 0.22);@table-expand-icon-bg: orange;@table-padding-vertical: 7px;@table-padding-horizontal: 10px;@menu-dark-bg: #001529;@menu-dark-arrow-color: #fff;@menu-dark-item-hover-bg: #258fe6f7;@label-color: black;@form-item-margin-bottom: 0px;@form-vertical-label-padding: 0 0 2px 2px;@form-error-input-bg: #f98087;@collapse-header-bg: #40a9ffb5;@collapse-content-padding: 5px;@collapse-content-bg: #fbfbfbd4;@card-head-color: black;@card-head-background: #e2b768;@card-head-padding: 16px;@card-head-padding-sm: 5px;@card-head-height: 24px;@card-inner-head-padding: 5px;@card-padding-base: 12px;@radio-button-checked-bg: orange;@radio-button-active-color: orange;@modal-header-bg: #6aafd8;@transfer-item-hover-bg: @table-row-hover-bg;